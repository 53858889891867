import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/parts/hero"
import Section from "../components/parts/section"
import Stats from "../components/parts/stats"
import HalfImageHalfContent from "../components/parts/halfimagehalfcontent"

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Elite Airborne - Simplifying the way the world moves things." />
    {data.allPrismicPage.nodes.map(page => (
      <>
        {page.data.body.map( section => (
          <>
            {section.slice_type === "hero_section" &&
              <Hero content={section} parent={page.data.title.text}/>  
            }
            {section.slice_type === "generic_section" &&
              <Section content={section} />  
            }
            {section.slice_type === "image_with_stats" &&
              <Stats content={section} />  
            }
            {section.slice_type === "1_2___1_2" &&
              <HalfImageHalfContent content={section} />  
            }

          </>
        ))}
      </>
    ))}
  </Layout>
)

export default IndexPage

export const query = graphql`
  {
    allPrismicPage(filter: {data: {title: {text: {eq: "Home"}}}}) {
      nodes {
        id
        data {
          title {
            text
          }
          body {
            ... on PrismicPageBodyHeroSection {
              id
              primary {
                background_color
                background_image {
                  url
                }
                hero_title {
                  html
                  text
                }
                hero_content {
                  html
                  text
                }
              }
              slice_type
            }
            ... on PrismicPageBodyGenericSection {
              id
              primary {
                section_name
                call_to_action
                button_text
                button_link {
                  url
                }
                section_background {
                  alt
                  url
                }
                bg_position
                background_size
                section_content {
                  html
                  text
                }
                section_title {
                  text
                }
              }
              items {
                iwt_title {
                  html
                  text
                }
                iwt_image {
                  url
                }
                iwt_content {
                  html
                  text
                }
              }
              slice_type
            }
            ... on PrismicPageBodyImageWithStats {
              id
              primary {
                section_name
                section_title {
                  text
                }
                content {
                  text
                }
                image {
                  url
                }
              }
              slice_type
              items {
                stat_icon {
                  url
                }
                key_fact
                fact_measurement
              }
            }
          }
        }
      }
    }
  }
`